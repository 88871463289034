import { useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

export const Right = () => {
  const [message, setMessage] = useState("");

  return (
    <div className="mt-8 overflow-x-hidden">
      <div className="bg-white mt-8 text-slate-600" id="about">
        <div className="pt-[50px] w-[35%]">
          <div
            className="bg-blue-600 text-center  flex items-center justify-center"
            style={{ borderRadius: "0px 0% 20%  0px" }}
          >
            <p className="text-white  text-base mb-0 py-2">About me</p>
          </div>
        </div>
        <div className="my-4 px-6 text-slate-600 pb-4">
          <p>
            Skilled software developer known for delivering high-quality
            solutions in dynamic environments. Proficient in JavaScript, Python,
            and PHP frameworks, I excel at problem-solving and thrive on
            continuous improvement. With a proven track record of collaboration
            and effective communication, I am passionate about contributing to
            innovative projects that push boundaries and drive success.
          </p>
        </div>
      </div>

      {/* Experience  */}

      {/* Projects */}

      <div className="bg-white mt-8 text-slate-600" id="portfolio">
        <div className="pt-[38px] w-[35%]">
          <div
            className="bg-blue-600 text-center  flex items-center justify-center mb-5"
            style={{ borderRadius: "0px 0% 20%  0px" }}
          >
            <p className="text-white pr-2 text-base  mb-0 py-2">Some of my projects</p>
          </div>
        </div>
        <div className="grid grid-cols-2 w-full gap-y-5 px-3 gap-x-3">
          <div className="card w-full">
            <img src="port1.png" className="card-img-top" alt="..." />
            <div className="card-body">
              <p className="card-text">Axion Mattress (Completed)</p>
              <a
                href="https://axionfoam.netlify.app/"
                className="btn btn-primary !text-sm -mt-3"
              >
                Go to website
              </a>
            </div>
          </div>
          <div className="card w-full">
            <img src="port6.png" className="card-img-top" alt="..." />
            <div className="card-body">
              <p className="card-text">Tammy (Completed)</p>
              <a
                href="https://tammy.com.ng/"
                className="btn btn-primary !text-sm -mt-3"
              >
                Go to website
              </a>
            </div>
          </div>
          <div className="card w-full">
            <img src="port7.png" className="card-img-top" alt="..." />
            <div className="card-body">
              <p className="card-text">Vediclifecare (Completed)</p>
              <a
                href="https://www.vediclifecare.com/"
                target="_blank"
                rel="noreferrer"
                className="btn btn-primary !text-sm -mt-3"
              >
                Go to website
              </a>
            </div>
          </div>

          <div className="card w-full">
            <img src="port2.png" className="card-img-top" alt="..." />
            <div className="card-body">
              <p className="card-text">Jobmagnet (In progress)</p>
              <a
                href="https://jobmagnet.netlify.app/"
                target="_blank"
                rel="noreferrer"
                className="btn btn-primary !text-sm -mt-3"
              >
                Go to website
              </a>
            </div>
          </div>
          <div className="card w-full">
            <img src="port5.png" className="card-img-top" alt="..." />
            <div className="card-body">
              <p className="card-text">Yellow card (Completed)</p>
              <a
                href="https://yellowcard.io/nigeria/"
                target="_blank"
                rel="noreferrer"
                className="btn btn-primary !text-sm -mt-3"
              >
                Go to website
              </a>
            </div>
          </div>
          <div className="card w-full">
            <img src="port3.png" className="card-img-top" alt="..." />
            <div className="card-body">
              <p className="card-text">Expertxcapital (Completed)</p>
              <a
                href="https://expertxcapital.com/"
                target="_blank"
                rel="noreferrer"
                className="btn btn-primary !text-sm -mt-3"
              >
                Go to website
              </a>
            </div>
          </div>
          <div className="card w-full">
            <img src="port4.png" className="card-img-top" alt="..." />
            <div className="card-body">
              <p className="card-text">Myskincaremall(Wordpress) (Completed)</p>
              <a
                href="https://www.myskincaremall.com/"
                target="_blank"
                rel="noreferrer"
                className="btn btn-primary !text-sm -mt-3"
              >
                Go to website
              </a>
            </div>
          </div>
        </div>

        <div className="mt-6 grid lg:grid-cols-2 gap-5"></div>
      </div>
      <div className="grid lg:grid-cols-2 md:mb-4 gap-x-4 gap-y-4">
        <div className="bg-white mt-8 text-slate-600">
          <div className="pt-[38px] w-[35%]">
            <div
              className="bg-blue-600 text-center  flex items-center justify-center"
              style={{ borderRadius: "0px 0% 20%  0px" }}
            >
              <p className="text-white  text-base  mb-0 py-1">Get in touch</p>
            </div>
          </div>
          <div className="mt-5 mx-5 lg:mx-1">
            <InputGroup className="mb-3 ">
              <InputGroup.Text id="basic-addon1">
                <i className="bi bi-person-lines-fill"></i>
              </InputGroup.Text>
              <Form.Control
                placeholder="Name"
                aria-label="Username"
                // onChange={(e) => setName(e.target.value())}
                aria-describedby="basic-addon1"
              />
            </InputGroup>
          </div>
          <div className="mt-4 mx-5 lg:mx-2">
            <InputGroup className="mb-3 ">
              <InputGroup.Text id="basic-addon1">
                <i className="bi bi-envelope"></i>
              </InputGroup.Text>
              <Form.Control
                placeholder="Email"
                aria-label="Email"
                aria-describedby="basic-addon1"
                // onChange={(e) => setEmail(e.target.value())}
              />
            </InputGroup>
          </div>
          <div className="mt-4 mx-5 lg:mx-2 ">
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
              onChange={(e) => {
                setMessage(e.target.value);
                console.log(message);
              }}
            >
              <Form.Label>Write Message</Form.Label>
              <Form.Control as="textarea" rows={1} className="h-[50px]" />
            </Form.Group>
          </div>
          <div className="flex justify-start mx-5 lg:mx-2 cursor-pointer mt-1  mb-4 ">
            <button className="button-26 !text-base !py-2 !uppercase rounded-2xl !w-full">
              {" "}
              Submit
            </button>
          </div>
        </div>
        <div>
          <div className="bg-white mt-8 text-slate-600" id="contact">
            <div className="pt-[38px] w-[35%]">
              <div
                className="bg-blue-600 text-center  flex items-center justify-center"
                style={{ borderRadius: "0px 0% 20%  0px" }}
              >
                <p className="text-white  text-base  mb-0 py-1">
                  Contact me on
                </p>
              </div>
            </div>
            <div className="mt-3">
              <div className="grid px-1 cursor-pointer">
                <div className="flex items-center ml-2">
                  <icon className="bi bi-facebook text-blue-600"></icon>
                  <h3 className="text-lg mt-2 ml-3">Facebook</h3>
                </div>
                <div className="flex items-center ml-2">
                  <icon className="bi bi-linkedin text-blue-600"></icon>
                  <h3 className="text-lg mt-2 ml-3">Linkedin</h3>
                </div>
                <div className="flex items-center ml-2">
                  <icon className="bi bi-twitter text-blue-600"></icon>
                  <h3 className="text-lg mt-2 ml-3">Twitter</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white mt-8 text-slate-600 h-[]">
            <div className="pt-[38px] w-[35%] ">
              <div
                className="bg-blue-600 text-center flex "
                style={{ borderRadius: "0px 0% 20%  0px" }}
              >
                <p className="text-white  text-base pl-2 mb-0 py-1">
                  Available on
                </p>
              </div>
            </div>
            <div className="flex  cursor-pointer px-3 py-3">
              <a
                href="https://www.linkedin.com/in/ajayi-oluwatamilore-b056a6206/"
                target="_blank"
                rel="noreferrer"
              >
                <icon className="bi bi-linkedin  text-3xl text-blue-700"></icon>
              </a>
              <a
                href="https://github.com/tammydufil"
                target="_blank"
                rel="noreferrer"
              >
                <icon className="bi bi-github ml-4 text-3xl text-blue-700 mb-8 "></icon>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
