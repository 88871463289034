// import React from "react";
// import image from "../utils/webimage.png";

import image1 from "../utils/devpic.png";
// import { SkillBar } from "react-skills";

export const Left = () => {
  return (
    <div>
      <div className=" rounded-md bg-blue-500 flex items-center flex-col justify-centers">
        <div className=" ">
          <img
            src={image1}
            alt=""
            className=" h-[130px] my-3 w-[130px] object-cover  "
          />
        </div>
        <h1 className="text-2xl text-white">Ajayi Oluwatamilore</h1>
        <h1 className="text-lg mb-6 text-white">Software Developer</h1>
      </div>

      <div className="mt-3">
        <div className="px-3">
          {/* <div className="flex justify-between">
            <p className="text-lg text-slate-600">Country:</p>
            <p className="text-lg text-slate-600">Nigeria</p>
          </div> */}
          <div className="flex justify-between">
            <p className="text-lg text-slate-600">Linkedin:</p>
            <a
              className="no-underline !text-lg"
              href="https://www.linkedin.com/in/ajayi-oluwatamilore-b056a6206/"
              target="_blank"
              rel="noreferrer"
            >
              <p className="text-base text-blue-600">Click here</p>
            </a>
          </div>

          <div className="flex justify-between">
            <p className="text-lg lg:text-base text-slate-600">
              Qualification:
            </p>
            <p className=" text-sm lg:text-xs text-right text-slate-600">
              BSC Computer Science <br />
              Hallmark university <br />
              (First class)
            </p>
          </div>
        </div>
      </div>

      <div>
        <h4>Skills</h4>

        <div className="flex flex-wrap gap-y-4 gap-x-3 mt-3">
          <div className="bg-blue-600 rounded-xl px-2 text-white hover:bg-blue-800 cursor-context-menu transition-all py-1 text-sm ">
            React/Next
          </div>

          <div className="bg-blue-600 rounded-xl px-2 text-white hover:bg-blue-800 cursor-context-menu transition-all py-1 text-sm ">
            Node/Express/Sequelize
          </div>
          <div className="bg-blue-600 rounded-xl px-2 text-white hover:bg-blue-800 cursor-context-menu transition-all py-1 text-sm ">
            Laravel
          </div>
          <div className="bg-blue-600 rounded-xl px-2 text-white hover:bg-blue-800 cursor-context-menu transition-all py-1 text-sm ">
            Mssql, Mysql, Mongo Db
          </div>
          <div className="bg-blue-600 rounded-xl px-2 text-white hover:bg-blue-800 cursor-context-menu transition-all py-1 text-sm ">
            Python/Tkinter
          </div>
          <div className="bg-blue-600 rounded-xl px-2 text-white hover:bg-blue-800 cursor-context-menu transition-all py-1 text-sm ">
            Tailwind, Material Ui, Bootstrap, Saas
          </div>
          <div className="bg-blue-600 rounded-xl px-2 text-white hover:bg-blue-800 cursor-context-menu transition-all py-1 text-sm ">
            Figma, Photoshop
          </div>

          <div className="bg-blue-600 rounded-xl px-2 text-white hover:bg-blue-800 cursor-context-menu transition-all py-1 text-sm ">
            IIS/Linux
          </div>
          <div className="bg-blue-600 rounded-xl px-2 text-white hover:bg-blue-800 cursor-context-menu transition-all py-1 text-sm ">
            Azure/Gcp
          </div>
          <div className="bg-blue-600 rounded-xl px-2 text-white hover:bg-blue-800 cursor-context-menu transition-all py-1 text-sm ">
            Redux/Context
          </div>
          <div className="bg-blue-600 rounded-xl px-2 text-white hover:bg-blue-800 cursor-context-menu transition-all py-1 text-sm ">
            Agile/Scrum
          </div>
          <div className="bg-blue-600 rounded-xl px-2 text-white hover:bg-blue-800 cursor-context-menu transition-all py-1 text-sm ">
            Wordpress
          </div>
        </div>
      </div>

      <hr className="mt-10" />
      <h3 className="text-lg font-semibold text-slate-700">Social Profiles</h3>
      <div className="flex  cursor-pointer">
        <a
          href="https://www.linkedin.com/in/ajayi-oluwatamilore-b056a6206/"
          target="_blank"
          rel="noreferrer"
        >
          <icon className="bi bi-linkedin  text-3xl text-blue-700"></icon>
        </a>
        <a
          href="https://github.com/tammydufil"
          target="_blank"
          rel="noreferrer"
        >
          <icon className="bi bi-github ml-4 text-3xl text-blue-700 mb-8 "></icon>
        </a>
      </div>
    </div>
  );
};
