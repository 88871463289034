import { Left } from "../subcomponents/left";
import { Right } from "../subcomponents/right";
import codeImg from "../utils/code.png";
import { TypeAnimation } from "react-type-animation";

export const Main = () => {
  return (
    <div className=" z-50 ">
      <div
        className="leftcomponent z-30  p-[3rem] lg:p-[1rem] bg-white lg:fixed left-0 top-0 lg:w-[350px]
      lg:h-[90vh] lg:overflow-y-scroll lg:mt-[35px] lg:ml-4"
      >
        <Left></Left>
      </div>
      <div className="lg:fixed  lg:overflow-y-scroll lg:h-[90vh] mt-[3px] lg:mt-[35px] lg:ml-[385px]">
        <div className=" relative" id="home">
          <div className="flex flex-col relative">
            <img
              src={codeImg}
              className="h-[90vh] object-cover codeImg"
              alt=""
            />
          </div>
          <div className="absolute top-0 p-5  " id="home">
            <div className="lg:mt-[130px]   mt-[130px] sm:mt-[130px]">
              <h1 className="text-4xl sm:text-5xl font-medium text-white uppercase">
                Hey there,
              </h1>
            </div>
            <h3 className="text-white text-base sm:text-xl mt-1">
              I&apos;m <span className="text-red-200">Ajayi Oluwatamilore</span>
              ,{" "}
              <TypeAnimation
                sequence={[
                  "I'm a Software Developer",
                  1000,
                  "I'm a Ui/Ux Designer",
                  1000,
                  "I'm a Hobbyist Pentester",
                  1000,
                ]}
                wrapper="span"
                speed={50}
                style={{ fontSize: "1.2rem", display: "inline-block" }}
                repeat={Infinity}
              />
            </h3>

            <div className="mt-4 mb-7 flex">
              <a href="#portfolio" style={{ textDecoration: "none" }}>
                <div className="button-3  text-black border-2 border-white bg-slate-300 hover:!text-white">
                  Portfolio
                </div>
              </a>
            </div>
          </div>
        </div>
        <Right></Right>
      </div>

      {/* Fixed bottom Navigator  */}

      <div className="fixed bg-gray-700 bottom-0 w-[100vw] lg:w-auto  lg:left-[37%] lg:right-[37%] z-50 lg:rounded-xl py-2 shadow-xl">
        <div className="flex justify-center  cursor-pointer">
          <a href="#home">
            <icon className="bi bi-house-down-fill text-white text-3xl hover:text-slate-900"></icon>
          </a>
          <a href="#about">
            <icon className="bi bi-person-lines-fill text-white text-3xl ml-7"></icon>
          </a>

          <a href="#portfolio">
            <icon className="bi bi-pc-display text-white text-3xl ml-7"></icon>
          </a>
          <a href="#contact">
            <icon className="bi bi-envelope-at-fill text-white text-3xl ml-7"></icon>
          </a>
        </div>
      </div>
      {/* Fixed bottom Navigator  */}
    </div>
  );
};
