import "./App.css";

import "bootstrap-icons/font/bootstrap-icons.css";
import { Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Main } from "./components/main";
import { Admin } from "./components/admin";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Main></Main>}></Route>
        <Route
          path="/ajayioluwatamiloreemmanueladmin"
          element={<Admin></Admin>}
        ></Route>
      </Routes>
    </div>
  );
}

export default App;
