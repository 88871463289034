import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";

export const Admin = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImage] = useState("");
  const [link, setLink] = useState("");
  const [top, setTop] = useState(false);
  const [description, setDescription] = useState("");
  const [pageData, setPageData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getPortfolio = async () => {
    console.log(name, category, image, link, top, description);

    try {
      setLoading(true);
      const data = await Axios.get(
        "https://anotherport.tammy.com.ng/api/getportfolio"
      );
      setPageData(data.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getPortfolio();
  }, []);

  const handleSubmit = async () => {
    console.log(name, category, image, link, top, description);

    try {
      setLoading(true);
      const data = await Axios.post(
        "https://anotherport.tammy.com.ng/api/addportfolio",
        {
          name,
          category,
          image,
          link,
          description,
          top,
        }
      );

      console.log(data);
      // setName("");
      // setCategory("");
      // setImage("");
      // setLink("");
      // setDescription("");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const deletePost = async (id) => {
    try {
      setLoading(true);
      await Axios.delete(
        `https://anotherport.tammy.com.ng/api/deleteportfolio/${id}`
      );
      const mydata = pageData.filter((item) => item.id !== id);
      setPageData(mydata);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return !loading ? (
    <div>
      <div className="p-3">
        <h3>Welcome to my admin page</h3>

        <button
          onClick={() => {
            navigate("/");
          }}
          className="bg-[red] p-3 text-[white] rounded"
        >
          Back to home
        </button>

        <div className="my-9">
          <div className="flex gap-20 flex-wrap justify-center">
            {pageData.map((item, index) => {
              return (
                <div key={index} className="border-2">
                  <h4>{item.name}</h4>
                  <p>{item.category}</p>
                  <img
                    src={item.image}
                    className="h-[200px] w-[200px]"
                    alt=""
                  />
                  <p>{item.link}</p>
                  <p>{item.description}</p>

                  <button
                    type="submit "
                    className="p-2 mt-4 rounded bg-[red] text-white mb-6"
                    onClick={() => {
                      deletePost(item.id);
                    }}
                  >
                    Delete
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="mt-3">
        <form
          action=""
          className="mx-5"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <input
            type="text"
            className="rounded p-2 text-xl w-[70vw] mt-5"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            placeholder="Name"
          />

          <br />
          <input
            type="text"
            className="rounded p-2 text-xl w-[70vw] mt-5"
            value={category}
            onChange={(e) => {
              setCategory(e.target.value);
            }}
            placeholder="Category"
          />
          <br />
          <input
            type="text"
            className="rounded p-2 text-xl w-[70vw] mt-5"
            value={link}
            onChange={(e) => {
              setLink(e.target.value);
            }}
            placeholder="Link"
          />
          <br />
          <input
            type="text"
            className="rounded p-2 text-xl w-[70vw] mt-5"
            value={image}
            onChange={(e) => {
              setImage(e.target.value);
            }}
            placeholder="Image"
          />
          <br />

          <textarea
            name=""
            id=""
            cols="30"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            className="mt-5 p-3 w-[70vw]"
            placeholder="Description"
          ></textarea>

          <br />
          <input
            type="text"
            className="rounded p-2 text-xl w-[70vw] mt-5"
            value={top}
            onChange={(e) => {
              setTop(e.target.value);
            }}
            placeholder="top"
          />
          <br />

          <button
            type="submit "
            className="p-2 mt-4 rounded bg-[red] text-white mb-6"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  ) : (
    <h4>Loading</h4>
  );
};
